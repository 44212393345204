<template>
  <v-menu  v-if="$route.name != 'page_policy'">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" v-if="$route.name == 'Login' || $route.name == 'SignUp'"
        >{{ $t("language") }} ({{ $store.state.app.language || "es" }})
        <v-icon>mdi-menu-down</v-icon></v-btn
      >
      <v-btn text v-on="on" v-else>
        {{ $store.state.app.language || "es" }}
        <v-icon>mdi-menu-down</v-icon></v-btn
      >
    </template>
    <v-list>
      <v-list-item
        v-for="language in $store.state.app.supportedLanguages"
        :key="language"
        @click="changeLanguage(language)"
        class="lessMargin"
      >
        <v-list-item-title>{{ $t(`languages.${language}`) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import languageHelper from "../../mixins/languageHelper";

export default {
  name: "DropdownLanguage",
  mixins: [languageHelper],
};
</script>
<style scoped lang="sass">
.lessMargin
  height: 30px
  cursor: pointer
</style>
